.design-container {
  width: 100%;
}

.card-layout > .ant-card-body {
  padding: unset;
}

.configurations {
  display: flex;
  flex-direction: column;
}

.configurations > .ant-row {
  margin-bottom: 8px;
}

.config-section {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}

.ant-input[type='color'] {
  height: 24px;
  padding: 1px 1px;
}

.config-section > .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: unset;
}

.w-100 {
  width: 100px;
}

.w-80 {
  width: 80px;
}

.editable-input {
  border: 1px dashed;
  background-color: transparent;
}

.flex {
  display: flex;
}

.column {
  flex-direction: column;
}

.row {
  flex-direction: row;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.mr-4 {
  margin-right: 4px;
}

.mb-16 {
  margin-bottom: 16px;
}
.mt-16 {
  margin-top: 16px;
}
.template-image {
  width: 350px;
  height: 200px;
}

.custom-file-upload {
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  cursor: pointer;
}

.input-number {
  width: 50px;
}

.card-container {
  padding-right: 16px;
}

.preview-modal .ant-modal {
  width: 100% !important;
}
.preview-wrapper {
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
}
.preview-text {
  margin-right: 1rem;
}
#cdc-wrapper {
  position: relative;
  width: 405px;
  height: 255px;
}
#edit-wrapper {
  position: relative;
  width: 405px;
  height: 255px;
}

.react-draggable {
  font-family: '' !important;
}
