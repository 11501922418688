.container {
  min-height: 100vh;
}

.load-button {
  margin-top: 8px;
}
.release-reassign-icon {
  height: 32px;
  width: 32px;
  padding: 8px;
}
.form-header {
  display: flex;
  justify-content: center;
  font-weight: 700;
  margin: 1rem auto;
}
.form-header-left {
  display: flex;
  justify-content: left;
  font-weight: 700;
  margin: 1rem auto;
}
.react-tel-input .form-control {
  display: none;
}
.react-tel-input .flag-dropdown {
  height: 32px;
}
.release-reassign-btn {
  width: 32px;
  padding: 0px;
}
.react-tel-input {
  position: unset !important;
}
