.rate-select.ant-select {
  width: 250px;
  font-size: 10px;
  background-color: #f8ffdb;
}
.rate-select span.ant-select-selection-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}
.rate-select.ant-select-disabled
  > .ant-select-selector
  > span.ant-select-selection-placeholder {
  color: red;
  font-size: 10px;
}
.rate-select.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: #f8ffdb;
  border: 1px solid #7dce13;
  padding: 0;
  height: 48px;
}
.rate-select > .ant-select-clear {
  top: 0;
  right: -12px;
}

.rate-select.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 20px;
}
.rates.ant-select-item {
  padding: 2px 0;
}

.rates .ant-select-item-option-content {
  font-size: 10px;
  background-color: #f8ffdb;
  border: 1px solid #7dce13;
  border-radius: 4px;
}
.rates.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  > .ant-select-item-option-content {
  background-color: white;
}
.service-logo {
  width: 30px;
  height: auto;
}

.service-name {
  display: block;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 3.6em;
  line-height: 1.8em;
}

.shipping-address {
  display: flex;
  flex-direction: row;
}
.address-text {
  margin-bottom: 0;
}
