.sidebar-menu {
  overflow-y: auto;
  height: calc(100vh - 194px);
}
.admin-access-switch {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ant-input-group-addon {
  background-color: transparent;
  border: none;
}
.admin-access-switch > .ant-switch-checked {
  background-color: #03ab00;
}
.mr-16 {
  margin-right: 16px;
}
.mb-8 {
  margin-bottom: 0.5rem;
}

.margin-24 {
  margin: 24px;
}
.modal-position {
  top: 20px;
}
.internal-chat-text > .ant-input-group .ant-input {
  color: #dc3545;
  background-color: unset;
}
.font-400 {
  font-weight: 400;
}
.font-14 {
  font-size: 14px;
}
.flip-card {
  background-color: transparent;
  width: 300px;
  height: 200px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */

/* Style the back side */
.flip-card-back {
  transform: rotateY(180deg);
}

.display-flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}
.card-image {
  width: 300px;
  height: 200px;
}

.cursor-pointer {
  cursor: pointer;
}

.list-overflow {
  max-height: 500px;
  overflow-y: auto;
}

.red-btn {
  color: red;
}

.card-close-icon {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
