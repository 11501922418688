.preview-modal .TransformComponent-module_container__3NwNd {
    position: relative;
    width: 2022px;
    height: 1275px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

div.card-preview {
    overflow: scroll !important;
}