.cards-header {
  display: flex;
  align-items: center;
}
.margin-b-16 {
  margin-bottom: 16px;
}
.scroll-top-btn {
  background-color: red;
  color: white;
}
.meesage-green {
  color: green;
  margin: 2px;
}
.anticon .message-grey {
  color: '#a0a0a0';
  margin: 2px;
}
.csv-download-btn {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 8px;
}
.csv-download-btn .ant-btn {
  margin-right: 16px;
}
.input-width-100 {
  min-width: 100px;
}
.editable-cell-value-wrap {
  display: flex;
  justify-content: center;
}
.assign-print-btn {
  width: 32px;
  padding: 0px;
}
.assign-print-icon {
  height: 28px;
  width: 24px;
}
.grey-img {
  opacity: 0.5;
}
.order-history-card .ant-card-head-title {
  flex: unset;
}
.order-history-card .ant-card-extra {
  margin-left: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}
