.news-container {
  min-height: 100vh;
}
.delete-btn:hover {
  color: red;
}
.img {
  max-height: 300px;
  max-width: 100%;
}
.text-center {
  text-align: center;
}
.date-text{
  color: rgb(145, 143, 143);
  font-size: 12px;
}
.confirmation-button {
  display: flex;
  justify-content: end;
  margin-top: 8px;
}
.delete-img-btn {
  position: absolute;
  bottom: 45%;
  left: 45%;
  font-size: 25px;
  cursor: pointer;
  opacity: 1;
  padding: 2px;
  color: red;
  background-color: white;
}
.category-dropdown {
  padding: 0 8px 4px;
}
