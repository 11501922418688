.margin-t-16 {
  margin-top: 16px;
}
.scroll-button {
  background-color: red;
  color: white;
}
.super-admin {
  font-weight: 700;
  color: blue;
  margin-left: 0.5rem;
}
.admin {
  font-weight: 700;
  color: #00ff00;
  margin-left: 0.5rem;
}
.container-loader {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
}

.available-license-table {
  margin: 1rem;
}
.ant-checkbox-disabled + span {
  color: #000;
}
.email-communication-dropdown > .ant-select {
  width: 150px;
}

.license-list .ant-list-item {
  min-width: 200px;
}
