:root{
  --resolutionFactorAdjuster: 1;
}
.transformZero{
  transform: translate(0,0) !important;
}
.rightAligned {
  right: 0 !important;
  left: auto !important;
}
.leftAligned {
  left: 0 !important;
  right: auto !important;
}
.design-experience-toggle-section {
  padding: 1rem;
}
.order-status-tag {
  font-size: 20px;
  padding: 0.5rem;
}
