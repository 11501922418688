.ais-SearchBox-form {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.ais-SearchBox-form > .ais-SearchBox-input {
  box-sizing: border-box;
  margin: 0;
  position: relative;
  display: inline-block;
  width: 270px;
  min-width: 0;
  padding: 4px 11px;
  color: #000000d9;
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.ais-SearchBox-form > .ais-SearchBox-submit,
.ais-SearchBox-reset {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px #00000004;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: #000000d9;
  border-color: #d9d9d9;
  background: #fff;
  width: 32px;
  height: 32px;
  padding: 2.4px 0;
  font-size: 16px;
  border-radius: 2px;
  vertical-align: -3px;
}
input.ais-SearchBox-input:focus-visible {
  outline: none;
}
