.customer-profile-container {
  min-height: 100vh;
}
.ant-descriptions {
  margin-top: 16px;
}
.text-center {
  text-align: center;
}
.credit-card {
  margin: 8px;
}
.warning-text{
  margin-bottom: 1rem;
}