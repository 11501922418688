.vendors-container {
  min-height: 100vh;
}
.vendor-header {
  display: flex;
  align-items: center;
}
.select-w-100 {
  width: 100%;
}
.scroll-to-top-btn {
  margin-top: 16px;
  background-color: red;
  color: white;
}
.margin-l {
  margin-left: 1em;
}
.card-color .ant-radio-inner {
    display: none;
}
.card-color .selected-card-border {
  border: 2px solid #646d7a;
  border-radius: 100%;
}